// <!-- 헤더 JAVASCRIPT -->
const UserID = document.querySelector(".userID");

//// [ 헤더 번역 ] 
function headers(){
    const HeaderCompanyName = document.querySelector(".HeaderA_info p"); // Ponigress
    const HeaderSearch = document.getElementById("search"); // ' 검색'
    
    const HeaderText = {
      en: { // 영어
        HeaderCompanyName: "Ponigress",
        HeaderSearch: " Search"
      },
      ko: { // 한국어
        HeaderCompanyName: "Ponigress",
        HeaderSearch: " 검색"
      }
    };
  const Language = navigator.language.split("-")[0];
  const Text = HeaderText[Language] || HeaderText.en;

  if (HeaderCompanyName !== null) HeaderCompanyName.textContent = Text.HeaderCompanyName;
  if (HeaderSearch !== null) HeaderSearch.placeholder = Text.HeaderSearch;
}
if(UserID){
    headers();
}






//// [ 검색 - 비디오 / 유저 ]
function headerJS(){
    const Search = document.getElementById("search");
    
    //// [ 검색어 로컬스토리지 저장 ]
    function saveSearch() {
        const SearchValue = document.getElementById("search").value.toLowerCase(); // 입력값을 소문자로 변환
        if (SearchValue !== "") {
            const CurrentDate = new Date();
            const ExpiryDate = new Date(CurrentDate.getTime() + 14 * 24 * 60 * 60 * 1000); // 현재 시간에서 14일 뒤의 시간
            // 로컬 스토리지에서 검색어 확인
            if (localStorage.getItem(SearchValue)) {
                // 검색어가 이미 존재하면, 만료일만 업데이트
                localStorage.setItem(SearchValue, ExpiryDate);
            } else {
                // 새로운 검색어 저장
                localStorage.setItem(SearchValue, ExpiryDate);
                updateSearchHistory();
            }
        }
    }



    //// [ 저장된 검색어 보여주기 / 삭제 / 클릭 시 검색되게 하기 ]
    function updateSearchHistory() {
        const HistoryList = document.getElementById("historyList");
        HistoryList.innerHTML = "";
        for (let i = 0; i < localStorage.length; i+=1) {
            const Key = localStorage.key(i);
            const ListItem = document.createElement("li");
            if (Key.includes('@tosspayments') || Key.includes('@payment-widget')){
                //
            } else{
                const Value = new Date(localStorage.getItem(Key));
                const CurrentDate = new Date();
                if (CurrentDate > Value) {
                    localStorage.removeItem(Key);
                }else{
                    ListItem.innerHTML = Key;
                    const DeleteButton = document.createElement("button");
                    DeleteButton.innerHTML = "X";
                    DeleteButton.classList.add("deleteTermButton");
                    DeleteButton.addEventListener("click",(event)=>{
                        event.stopPropagation();
                        localStorage.removeItem(Key);
                        updateSearchHistory();
                    })
                    ListItem.appendChild(DeleteButton);
                    HistoryList.appendChild(ListItem);
                }
            }
            ListItem.addEventListener("click",()=>{
                document.getElementById("search").value = Key;
                const SearchClicks = document.getElementById('searchClick');
                SearchClicks.href = `#search/${Key}`;
                SearchClicks.click();
                document.getElementById("historyList").style.display = "none";
            })
        }
    }


    
    //// [ 검색창 제외 다른곳 클릭 시 ]
    function searchNone(event) {
        if(event.target !== Search){
            Search.style ="background-color:rgb(241, 241, 241); border-top-left-radius:none; border-top-right-radius:none; font-size:15px; color:black;";
            document.getElementById("historyList").style.display = "none";
            setTimeout(() => {
                window.removeEventListener("click" , searchNone  );
            }, 200);
        }
    }



    //// [ 검색창 클릭 시 ]
    document.getElementById("search").addEventListener("click",()=>{
        updateSearchHistory();
        Search.style ="background-color:white; border-radius:0px; border-top-left-radius:7px; border-top-right-radius:7px;  font-size:15px; color:black; box-shadow: 0.5px 0.5px 10px 1px rgba(102, 102, 102, 0.2);";
        document.getElementById("historyList").style.display = "block";
        setTimeout(() => {
            window.addEventListener("click" , searchNone );
        }, 200);
    });


    
    
    //// [ 검색어 해시체인지 / 로드 ]
    function headerSearch() {
        const AllUserBackground = document.querySelector('.allUser_background');
        if (window.location.hash.includes('#search/')){
            const SearchHash = window.location.hash;
            const SearchTitle = SearchHash.replace('#search/', '');
            const DecodedString = decodeURIComponent(SearchTitle).toLowerCase().replace(/\s+/g, ''); // 디코딩하고, 소문자로 변환하고, 앞뒤 공백을 제거합니다.
            const Decode  = decodeURIComponent(SearchTitle); // 유저가 검색한 검색어를 value 로 하고 싶어서 지정함.
            
            Search.value = Decode ; 
    
            // 비디오 블락검색
            document.querySelectorAll('.videoBlock .Block').forEach(function(blocks) {
                const Block = blocks;
                const TitleElement = Block.querySelector('.thumbnailTitle');
                if (TitleElement) {
                    const Title = TitleElement.textContent.toLowerCase().replace(/\s+/g, '');
                    AllUserBackground.style.display="none";
                    if (Title.includes(DecodedString)) {
                        Block.style.display = '';
                    } else {
                        Block.style.display = 'none';
                    }
                }
            });
    
            // 유저 검색
            document.querySelectorAll('.allUser .user-container').forEach(function(user) {
                const Name = user.querySelector('.userNames');
                if (Name) {
                    const Title = Name.textContent.toLowerCase().replace(/\s+/g, '');
                    if (Title.includes(DecodedString)) {
                        user.style.display = '';
                        const UserAvatars = user.querySelector('.userAvatars');
                        const UserAvatarsImg = user.querySelector('.userAvatarsImg');
                        AllUserBackground.style.display="flex";
                        if(UserAvatars.textContent === ""){
                            UserAvatarsImg.src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
                        } else {
                            UserAvatarsImg.src = UserAvatars.textContent;
                        }
                    } else {
                        user.style.display = 'none';
                    }
                }
            });
        }else {
            Search.value = "" ;
            // 비디오 블락은 비디오디테일 자바스크립트에 지정해줬다.
            document.querySelectorAll('.allUser .user-container').forEach(function(users) {
                const User = users;
                const UserAvatarsImg = User.querySelector('.userAvatarsImg');
                UserAvatarsImg.src = '';
            });
            AllUserBackground.style.display="none";
        }
    }
    headerSearch();


    //// [ 검색어를 a 태그 주소로 지정하고 클릭하기(주소로 이동) ]
    const SearchClick = document.getElementById('searchClick');
    Search.addEventListener('keyup', function(event) {
        if (event.key === 'Enter') {
            const searchTerm = Search.value;
            SearchClick.href = `#search/${searchTerm}`;
            SearchClick.click();
    
            document.getElementById("historyList").style.display = "none";
            saveSearch();
        }
    });
}
if(UserID){
    window.addEventListener('hashchange', headerJS);
    window.addEventListener('load', headerJS);
}