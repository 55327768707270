// <!-- 유저디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");
const UserDetailBg = document.querySelector(".userDetail-bg");
const UdvideoBlockBg = document.querySelector(".uDvideoBlock-bg");
function userDetailJS() {
  let width = window.innerWidth;
  if (window.location.hash.includes('#users')) {
    document.querySelectorAll('.uDthumbnailTitle').forEach((title) => {
      title.style.display = 'none'; // 제목 숨기기
    });
    UserDetailBg.style.display = "block";
    if (width > 1000) {
      UdvideoBlockBg.style =" width:100%; height:auto; padding-top:20px; padding-bottom:70px; padding-left:100px; padding-right:100px; opacity:1; pointer-events:auto;";
    } else if (width <= 1000 && width > 540) {
      UdvideoBlockBg.style =" width:100%; height:auto; padding-top:0px; padding-bottom:70px; padding-left:20px; padding-right:20px; opacity:1; pointer-events:auto;";
    } else if (width <= 540 && width > 340) {
      UdvideoBlockBg.style =" width:100%; height:auto; padding-top:0px; padding-bottom:70px; padding-left:0px; padding-right:0px; opacity:1; pointer-events:auto;";
    }else if (width <= 340) {
      UdvideoBlockBg.style =" width:100%; height:auto; padding-top:0px; padding-bottom:70px; padding-left:0px; padding-right:0px; opacity:1; pointer-events:auto;";
    }
  } else {
    document.querySelectorAll('.uDthumbnailTitle').forEach((title) => {
      title.style.display = 'block'; // 제목 숨기기
    });
    UserDetailBg.style.display = "none";
    UdvideoBlockBg.style ="width:0px; height:0px; padding-top:0px; padding-bottom:0px; padding-left:0px; padding-right:0px; opacity:0; pointer-events:none;";
  }
}
if(UserID){
  window.addEventListener('hashchange', userDetailJS);
  window.addEventListener('load', userDetailJS);
  window.addEventListener('resize', userDetailJS);
}