const LandingBg = document.querySelector(".landingBg");
function landingJS(){
  if(window.location.hash === "#landing"){
    LandingBg.style.display="flex";
  }else{
    LandingBg.style.display="none";  
  }
}
if(LandingBg){
  window.addEventListener('hashchange', landingJS);
  window.addEventListener('load', landingJS);
}