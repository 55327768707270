import "../scss/styles.scss";
import "./home";
import "./NotLogin/landing";
import "./NotLogin/login";
import "./NotLogin/signUp";
import "./NotLogin/findPw";
import "./NotLogin/findPw";

import "./Video/videoBlock";
import "./Video/upload";
import "./Video/detail";
import "./Partial/header";
import "./Partial/homeLoading";
import "./Partial/mobileSearch";
import "./User/editProfile";
import "./User/withdrawal";
import "./User/changePassword";
import "./User/userDetail";
import "./Company/privacyModalText";
import "./Company/termsModalText";
import "./Inquiry/inquiryUpload";
import "./Partial/mobileHeaderBottom";