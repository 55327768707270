// <!-- 모바일 header/bottom JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 모바일 header ]
function mobileHeaderJS(){
  const MobileHeader = document.querySelector('.mobileHeader');
  const Hash = window.location.hash;

  // 표시할 해시 값들을 배열로 설정
  const validHashes = ['#home', '#search'];
  
  if (window.innerWidth > 1000) {
    // 화면 크기가 1000px 초과일 경우 무조건 안보이게
    MobileHeader.style = 'display:none';
  } else if (!Hash || validHashes.some(validHash => Hash.includes(validHash))) {
    MobileHeader.style = 'display:flex;';
  } else {
    MobileHeader.style = 'display:none';
  }
}
if(UserID){
  window.addEventListener('hashchange', mobileHeaderJS);
  window.addEventListener('load', mobileHeaderJS);
  window.addEventListener('resize', mobileHeaderJS);
}









//// [ 모바일 bottom ]
function mobileBottomJS() {
  const MobileBottom = document.querySelector('.mobileBottom');
  MobileBottom.style.display = 'none';
  const Hash = window.location.hash;

  // 표시할 해시 값들을 배열로 설정
  const validHashes = ['#home','#search', '#users','#mobilesearch', '#videodetail', '#uDvideodetail'];
  
  if (window.innerWidth > 1000) {
    MobileBottom.style.display = 'none';
  } else if (!Hash || validHashes.some(validHash => Hash.includes(validHash))) {
    if (Hash.includes('#search')) {
      setTimeout(() => {
        MobileBottom.style.display = 'block';
      }, 300);
    } else {
      MobileBottom.style.display = 'block';
    }
  } else {
    MobileBottom.style.display = 'none';
  }
}
if (UserID) {
  window.addEventListener('hashchange', mobileBottomJS);
  window.addEventListener('load', mobileBottomJS);
  window.addEventListener('resize', mobileBottomJS);
}







//// [ 모바일 bottom 아이콘 클릭 시 css] MobileBottomUploadIcon
function MobileBottomIcon(){
  const MobileBottomHomeIcon = document.querySelector('.mobileBottom-home i');
  const MobileBottomSearchIcon = document.querySelector('.mobileBottom-search i');
  const MobileBottomNotiIcon = document.querySelector('.mobileBottom-noti i');
  const MobileBottomUploadIcon = document.querySelector('.mobileBottom-upload i');
  const MobileBottomUserAvatar = document.querySelector('.mobileBottom-userAvatar');
  MobileBottomHomeIcon.style="color:black";
  MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
  MobileBottomNotiIcon.style="color:rgb(141, 141, 141);";
  MobileBottomUploadIcon.style="color:rgb(141, 141, 141); border:2px solid rgb(141, 141, 141);";
  MobileBottomUserAvatar.style="padding:0px; border:0px solid black";
  const Hash = window.location.hash;
  if(!Hash || Hash.includes('#home')){
    MobileBottomHomeIcon.style="color:black";
    MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
    MobileBottomUserAvatar.style="padding:0px; border:0px solid black";
  }else if(Hash.includes('#mobilesearch')){
    MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
    MobileBottomSearchIcon.style="color:black";
    MobileBottomUserAvatar.style="padding:0px; border:0px solid black";
  }else if(Hash.includes('#users')){
    MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
    MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
    MobileBottomUserAvatar.style="padding:2px; border:1px solid black";
  } else{
    MobileBottomHomeIcon.style="color:rgb(141, 141, 141);";
    MobileBottomSearchIcon.style="color:rgb(141, 141, 141);";
    MobileBottomUserAvatar.style="padding:0px; border:0px solid black";
  }
}
if(UserID){
  window.addEventListener('hashchange', MobileBottomIcon);
  window.addEventListener('load', MobileBottomIcon);
}