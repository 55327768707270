// <!-- 비디오블락 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 비디오 블락 해시에 따른 css 변경 ]
function videoBlocksCss(){
  const Hash = window.location.hash;
  let width = window.innerWidth;
  const VideoBlockBg = document.querySelector('.videoBlock-bg');
  const VideoBlockLoadingContainer = document.querySelector('.videoBlock-loading-container');
  const VideoBlock = document.querySelector('.videoBlock');
  if (Hash.includes('#videodetail') || Hash.includes('#uDvideodetail')){
    document.querySelectorAll('.thumbnailTitle').forEach((title) => {
      title.style.display = 'block'; // 썸네일 제목 보이기
    });
    if (width > 1000) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:5; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:0px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:100px; padding-right:100px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    } else if (width <= 1000 && width > 540) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:4; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:0px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:20px; padding-right:20px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    } else if (width <= 540 && width > 340) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:2; column-gap: 10px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:0px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:10px; padding-right:10px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    }else if (width <= 340) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:2; column-gap: 10px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:0px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:10px; padding-right:10px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    }
  }else if(Hash.includes('#mobilesearch')){
    document.querySelectorAll('.thumbnailTitle').forEach((title) => {
      title.style.display = 'none'; // 썸네일 제목 숨기기
    });
    if (width > 1000) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:5; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:100px; padding-right:100px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    } else if (width <= 1000 && width > 540) {
      VideoBlockLoadingContainer.style="display:flex; height:130px; padding-top:70px;";
      VideoBlock.style="column-count:4; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:20px; padding-right:20px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    } else if (width <= 540 && width > 340) {
      VideoBlockLoadingContainer.style="display:flex; height:130px; padding-top:70px;";
      VideoBlock.style="column-count:3; column-gap: 5px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:5px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:10px; padding-right:10px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    }else if (width <= 340) {
      VideoBlockLoadingContainer.style="display:flex; height:127px; padding-top:67px;";
      VideoBlock.style="column-count:3; column-gap: 5px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:5px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:10px; padding-right:10px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    }
  }else if(!Hash || Hash.includes('#home') || Hash.includes('#search')){
    document.querySelectorAll('.thumbnailTitle').forEach((title) => {
      title.style.display = 'block'; // 썸네일 제목 보이기
    });
    if (width > 1000) {
      VideoBlockLoadingContainer.style="display:none; height:0px; padding-top:0px;" ;
      VideoBlock.style="column-count:5; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:80px; padding-left:100px; padding-right:100px; padding-bottom:70px; transform:translateY(-0px); transition:transform 0s ease-in-out;";
    } else if (width <= 1000 && width > 540) {
      VideoBlockLoadingContainer.style = "display:flex; height:122px; padding-top:52px;";
      VideoBlock.style="column-count:4; column-gap: 15px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:20px; padding-right:20px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    } else if (width <= 540 && width > 340) {
      VideoBlockLoadingContainer.style = "display:flex; height:122px; padding-top:52px;";
      VideoBlock.style="column-count:2; column-gap: 10px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; -top:0px; padding-left:10px; padding-right:10px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    }else if (width <= 340) {
      VideoBlockLoadingContainer.style="display:flex; height:119px; padding-top:59px;";
      VideoBlock.style="column-count:2; column-gap: 10px;";
      document.querySelectorAll('.Block').forEach((block) => {
        block.style="margin-bottom:15px;"
      });
      VideoBlockBg.style="width: 100%; height:auto; opacity:1; pointer-events:auto; padding-top:0px; padding-left:10px; padding-right:10px; padding-bottom:0px; transform:translateY(-70px); transition:transform 0s ease-in-out;";
    }
  }else{
    VideoBlockBg.style = "width:0px; height:0px; opacity:0; pointer-events:none;";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoBlocksCss);
  window.addEventListener('load', videoBlocksCss);
  window.addEventListener('resize', videoBlocksCss);
}















//// [ 비디오 썸네일 정렬 및 순서변경 ]
function updateTumbnailRandom(){
  let SavedOrder = [];  // 이전 배치 순서를 저장할 배열
  const VideoBlock = document.querySelector('.videoBlock');
  const Blocks = document.querySelectorAll('.Block');
  const VideoArray = Array.from(Blocks);
  
  // 간단한 랜덤 정렬을 통해 순서를 섞음
  let ShuffledVideos = VideoArray.sort(() => Math.random() - 0.5);
  
  // 이전에 저장된 순서(SavedOrder)가 있을 때 동일한 순서가 나오지 않도록 처리
  if (SavedOrder.length && ShuffledVideos.every((Video, Index) => Video === SavedOrder[Index])) {
    // 배열의 첫 번째 요소와 두 번째 요소를 교환
    const Temp = ShuffledVideos[0];
    ShuffledVideos[0] = ShuffledVideos[1];
    ShuffledVideos[1] = Temp;
  }
  
  // DocumentFragment를 사용하여 성능 최적화
  const Fragment = document.createDocumentFragment();
  
  ShuffledVideos.forEach(Video => {
    Fragment.appendChild(Video);  // 각 비디오 블록을 DocumentFragment에 추가
  });
  
  // 모든 요소를 한 번에 부모 요소에 추가
  VideoBlock.innerHTML = ''; // 기존 요소를 모두 제거
  VideoBlock.appendChild(Fragment); // 변경된 순서를 반영
  
  // 현재 ShuffledVideos 배열을 SavedOrder 배열에 저장
  SavedOrder = [...ShuffledVideos];
  
  // 저장된 순서를 콘솔에 출력 (확인용)
  //console.log("저장된 순서:", SavedOrder);
}
if(UserID){
  updateTumbnailRandom();
}








//// [ 썸네일 업데이트 해시 ]
function tumbnailUpdateHash() {
  const Hash = window.location.hash;
  if(Hash.includes('#mobilesearch') || Hash.includes('#home')){
    updateTumbnailRandom();
  }
}
if(UserID){
  window.addEventListener('hashchange', tumbnailUpdateHash);
}








//// [ 비디오 블락 로딩 ]
const pStart = { y: 0 }; // 터치 시작 좌표
const VideoBlockBg = document.querySelector('.videoBlock-bg');
const bodyContent = document.querySelector(".bodyContent");
let isLoading = false; // 로딩 상태 추적
let swipeListenerAdded = false; // 리스너 추가 상태

// 1초 대기 함수 (Promise)
const waitForThreeSeconds = () => new Promise(resolve => setTimeout(resolve, 1000));

// 로딩 애니메이션
const loading = () => {
  isLoading = true;
  VideoBlockBg.style.transition = 'transform 0.4s ease-in-out';
  VideoBlockBg.style.transform = 'translateY(0px)';
  
  setTimeout(() => {
    VideoBlockBg.style.transform = `translateY(-70px)`; // 위치 변경
    isLoading = false;
    updateTumbnailRandom(); // 썸네일 갱신
  }, 500); // 0.5초 대기
};

// 스와이프 이벤트 처리
const handleSwipe = (e) => {
  const touchY = e.changedTouches ? e.changedTouches[0].screenY : 0;
  const changeY = touchY > pStart.y ? Math.abs(touchY - pStart.y) : 0;
  
  const Hash = window.location.hash;
  if (changeY > 100 && bodyContent.scrollTop === 0 && !isLoading) {
    if (!Hash.includes('#videodetail') && !Hash.includes('#uDvideodetail')) {
      loading();
    }
  }
};

// 터치 이벤트 리스너 관리
const manageSwipeListeners = (add) => {
  if (add && !swipeListenerAdded) {
    bodyContent.addEventListener("touchstart", e => pStart.y = e.touches[0].screenY);
    bodyContent.addEventListener("touchmove", handleSwipe);
    swipeListenerAdded = true;
  } else if (!add && swipeListenerAdded) {
    bodyContent.removeEventListener("touchstart", e => pStart.y = e.touches[0].screenY);
    bodyContent.removeEventListener("touchmove", handleSwipe);
    swipeListenerAdded = false;
  }
};

// 스크롤 상단에서 1초 대기 후 리스너 추가
const initSwipeLoading = async () => {
  // 스크롤이 상단에 있고 화면 크기가 1000px 이하인 경우에만 동작
  if (bodyContent.scrollTop === 0 && window.innerWidth <= 1000) {
    await waitForThreeSeconds(); // 1초 대기
    manageSwipeListeners(true);  // 리스너 추가
  } else {
    manageSwipeListeners(false); // 리스너 제거
  }
};

// 해시 변경 시 기존 리스너 삭제 및 조건에 맞게 새로 추가
const handleHashChange = () => {
  manageSwipeListeners(false); // 해시가 변경되면 기존 리스너 삭제
  initSwipeLoading();           // 새로 조건에 맞춰 리스너 추가
};

// 이벤트 설정
if (UserID) {
  window.addEventListener('hashchange', handleHashChange); // 해시 변경 시 리스너 관리
  window.addEventListener('load', initSwipeLoading);       // 페이지 로드 시 리스너 추가
  bodyContent.addEventListener('scroll', initSwipeLoading); // 스크롤 시 리스너 추가/삭제 관리
}