let isLoginBtnListenerAdded = false;

const Login = document.querySelector(".login");
function loginJS() {
  const LoginEmail = document.querySelector(".login_email"); // 이메일
  const LoginPassword = document.querySelector(".login_password"); // 패스워드
  if (window.location.hash === "#login") {
    Login.style.display = "flex"; // 로그인 페이지를 보여주자!

    //// [ 로그인 언어번역 ]
    const LoginBtn = document.querySelector(".login_btn"); // 로그인 (버튼 텍스트)
    const LoginForgotPassword = document.querySelector(".login_Forgot_Pw"); // 비밀번호를 잊으셨나요?
    const SignUpNow = document.querySelector(".signUpNow"); // 회원가입 바로가기

    const LoginText = {
      en: { // 영어
        LoginEmail: "Email",
        LoginPassword: "Password",
        LoginBtn: "Sign In",
        LoginForgotPassword: "Forgot Password?",
        SignUpNow: "Sign Up"
      },
      ko: { // 한국어
        LoginEmail: "이메일",
        LoginPassword: "비밀번호",
        LoginBtn: "로그인",
        LoginForgotPassword: "비밀번호를 잊으셨나요?",
        SignUpNow: "회원가입"
      }
    }

    function getLogin() {
      const language = navigator.language.split("-")[0];
      const text = LoginText[language] || LoginText.en; // 브라우저 해당언어가 없다면 en(영어)를 반환해라!

      if (LoginEmail !== null) LoginEmail.placeholder = text.LoginEmail;
      if (LoginPassword !== null) LoginPassword.placeholder = text.LoginPassword;
      if (LoginBtn !== null) LoginBtn.textContent = text.LoginBtn;
      if (LoginForgotPassword !== null) LoginForgotPassword.textContent = text.LoginForgotPassword;
      if (SignUpNow !== null) SignUpNow.innerHTML = text.SignUpNow + '<i class="bi bi-chat" style="margin-left:5px;"></i>';
    }
    getLogin();

    //// [ 로그인 버튼로딩 및 제출 fetch ]
    if (LoginBtn !== null) {
      function LoginLoadings() {
        if (LoginEmail.value === "" || LoginPassword.value === "") {
          alert("이메일과 비밀번호를 모두 입력해주세요. 로그인 ");
        } else {
          LoginBtn.innerHTML = '<div class="loader"></div>'; // 로딩 표시 추가

          // 데이터를 서버에 보내기 위한 fetch 요청
          const loginData = {
            email: LoginEmail.value,
            password: LoginPassword.value
          };

          fetch('/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
          })
          .then(response => response.json())
          .then(data => {
            LoginBtn.innerHTML = '로그인'; // 버튼 텍스트 복원
            if (data.error) {
              // 에러 메시지 처리
              alert(data.error);
            } else {
              window.location.href = '/';
            }
          });
        }
      }
      if (!isLoginBtnListenerAdded) {
        LoginBtn.addEventListener("click", LoginLoadings);
        isLoginBtnListenerAdded = true;
      }
    }
  } else {
    Login.style.display = "none"; // 로그인 페이지를 숨기자
    LoginEmail.value = "";
    LoginPassword.value = "";
  }
}
if (Login) {
  window.addEventListener('hashchange', loginJS);
  window.addEventListener('load', loginJS);
}
