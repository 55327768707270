// <!-- 비번찾기 JAVASCRIPT -->

const FindPw = document.querySelector(".findPw");
function findPwJS(){
    if(window.location.hash === "#findpw"){
        FindPw.style.display="flex";

        //// [ 비밀번호 찾기 언어번역 ]
        const FindPwTitle = document.querySelector(".findPw_title"); // 비밀번호 찾기(제목)
        const FindPwEmail = document.querySelector(".findPw_email"); // 이메일을 입력하여주세요
        const FindPwButton = document.querySelector(".findPw_button"); // 확인(버튼)
        const FindPwForgotId = document.querySelector(".findPw_forgot_id p"); // 이메일을 잊으셨나요?
        
        const FindPwText = {
            en: { // 영어
                FindPwTitle: "Find Password",
                FindPwEmail: "Please enter your email",
                FindPwButton: "Confirm",
                FindPwForgotId: "Forgot email?"
            },
            ko: {  // 한국어
                FindPwTitle: "비밀번호 찾기",
                FindPwEmail: "이메일을 입력해주세요.",
                FindPwButton: "확인",
                FindPwForgotId: "이메일을 잊으셨나요?"
            }
        };
        function getLang(){
            const Language = navigator.language.split("-")[0];
            const Text = FindPwText[Language] || FindPwText.en;

            if (FindPwTitle !== null) FindPwTitle.textContent = Text.FindPwTitle;
            if (FindPwEmail !== null) FindPwEmail.placeholder = Text.FindPwEmail;
            if (FindPwButton !== null) FindPwButton.value = Text.FindPwButton;
            if (FindPwForgotId !== null) FindPwForgotId.textContent = Text.FindPwForgotId;
        }
        getLang();





            
        //// [ 알림 언어번역 ]  
        const FindPwForm = document.getElementById("findPwForm");
        const TemporaryPwEmail = {
            en: "We have sent a temporary password to your email", // 영어
            ko: "임시 비밀번호가 이메일로 전송되었습니다" // 한국어
        };
        function alerts() {
            const Language = navigator.language.split("-")[0];
            const TemporaryPwEmails = TemporaryPwEmail[Language] || TemporaryPwEmail.en;
            alert(TemporaryPwEmails);
        }



        //// [ 알림 언어번역 ] 
        const UserNotFound  = {
            en: "User not found", // 영어
            ko: "사용자를 찾을 수 없습니다" // 한국어
        };
        function alertss() {
            const Language = navigator.language.split("-")[0];
            const UserNotFounds = UserNotFound[Language] || UserNotFound.en;
            alert(UserNotFounds);
        }

        

        //// [ 알림 언어번역 / fetch ] 
        const EmailMS = {
            en: "temporary password", // 영어
            ko: "임시 비밀번호" // 한국어
        };
        const sendPwNumber = async (Email) => {
            try {
                const Language = navigator.language.split("-")[0];
                const EmailMassage = EmailMS[Language] || EmailMS.en;
                fetch("/api/findPwAjax",{
                    method: "POST",
                    headers: {
                        "Content-Type":"application/json",
                    },
                    body:JSON.stringify({
                        Email,
                        EmailMassage
                    }),
                })
                .then((response) => {
                    if(response.status === 200){
                        //console.log(response);
                        alerts();
                        window.location.href="https://www.ponigress.com/#landing/#login"; // 배포시 도메인 변경
                    }else if(response.status === 230) {
                        alertss();
                    }
                })
            }catch(e){
                //console.log(e);
            }
        };
        const HandleSubmit = event => {
            event.preventDefault();
            const Email = document.getElementById("email").value;
            sendPwNumber(Email);
        };
        function findPwInit(){
            FindPwForm.addEventListener("submit", HandleSubmit);
        }
        if(FindPwForm){
            findPwInit();
        }
    } else {
        FindPw.style.display="none";
    }
}
if(FindPw){
    window.addEventListener('hashchange', findPwJS);
    window.addEventListener('load', findPwJS);
}