// <!-- 비디오디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오디테일 해쉬 ]
function videoDetailHash() {
  const VideoDetail = document.querySelector('.videoDetail');
  VideoDetail.style.display = 'none';
}
if(UserID){
  window.addEventListener('hashchange', videoDetailHash);
}



//// [ 비디오디테일 로드 ]
function videoDetailLoad() {
  const VideoDetail = document.querySelector('.videoDetail');
  const Hash = window.location.hash;
  if(Hash.includes('#videodetail') || Hash.includes('#uDvideodetail')){
    VideoDetail.style.display = 'flex';
  }else{
    VideoDetail.style.display = 'none';
  }
}
if(UserID){
  window.addEventListener('load', videoDetailLoad);
}










// --------------------------- 여기서 부터는 해시 변경 시
//// [ 해시 저장버튼 클릭 시 ]
let removeUdVideoBlock = "" ;
function hashSaveBtn(){
  const Hash = window.location.hash;
  const HashVideo = document.querySelector('.hashVideo');
  const SaveValue = HashVideo.querySelector('.saveValue');
  const UdSaveValue = HashVideo.querySelector('.uDsaveValue');
  if(SaveValue && Hash.includes('#videodetail')){
    const BlockSaveBtn = HashVideo.querySelector('.block-saveBtnBg button');
    const VideoIdString = Hash.split('videoId=')[1];
    const VideoId = VideoIdString.split('/?')[0];
    const UdBlock = document.querySelector(`.uD-Block[data-id="${VideoId}"]`);
  
    if(SaveValue.textContent === "no"){
      BlockSaveBtn.textContent = "저장하기";
      BlockSaveBtn.style="background-color:blue; color:white;";
    } else {
      BlockSaveBtn.textContent = "저장완료";
      BlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
    }
  
    // BlockSaveBtn 버튼 클릭 시 
    if(BlockSaveBtn && !BlockSaveBtn.classList.contains('addListener')){
      function blockSaveBtnClick(){
        if(SaveValue.textContent === "no"){
          BlockSaveBtn.textContent = "저장완료";
          BlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
          SaveValue.textContent = "yes";
          fetch(`/api/${VideoId}/save`, { method: "POST" });

          // 유저 디테일 비디오블락 부분 동일하게 변경
          if(UdBlock){
            const UdBlockSaveBtn = UdBlock.querySelector('.uDblock-saveBtn');
            const UdSaveValue = UdBlock.querySelector('.uDsaveValue');
            UdBlockSaveBtn.textContent = "저장완료";
            UdBlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
            UdSaveValue.textContent = "yes";
          }
          
          // 나의 피드에 추가
          const UdVideoBlock = document.querySelector('.uDvideoBlock');
          const HashVideoBlock = HashVideo.querySelector('.Block');
          const VideoID = HashVideoBlock.querySelector('.block-videoId').textContent.trim();
          const HlsVideoUrl = HashVideoBlock.querySelector('.hlsVideoUrl').textContent.trim();
          const ThumbnailUrl = HashVideoBlock.querySelector('.thumbnailUrl').textContent.trim();
          const ThumbnailTitle = HashVideoBlock.querySelector('.thumbnailTitle').textContent.trim();          
          if(HashVideoBlock){
            const UdvideoBlocks = document.createElement('div');
            UdvideoBlocks.innerHTML = `
              <div class="uD-Block" data-videoUrl="${HlsVideoUrl}" data-id="${VideoID}">
                <a href="#uDvideodetail/videoId=${VideoID}/?${HlsVideoUrl}">
                  <div class="uDthumbnail-bg">
                    ${ThumbnailUrl === "thumbnail-None" ? `
                      <video class="uDthumbnail uDthumbnailVideos" id="hlsVideoId-${VideoID}" muted autoplay loop controlsList="nodownload"></video>
                    ` : `
                      <img class="uDthumbnail uDthumbnailImg" src="${ThumbnailUrl}" alt="Img">
                      <video class="uDthumbnail uDthumbnailVideo" id="hlsVideoId-${VideoID}" muted autoplay loop controlsList="nodownload"></video>
                    `}
                  </div>
                </a>
                <span class="uDthumbnailTitle">${ThumbnailTitle}</span>
                <div class="uDblock-saveBtnBg">
                  <button class="uDblock-saveBtn"></button>
                </div>
                <span class="uDsaveValue hidden">yes</span>
                <span class="uDvideoUrl hidden">${HlsVideoUrl}</span>
                <span class="uDthumbnailUrl hidden">${ThumbnailUrl}</span>
              </div>
            `;
            
            if (UdVideoBlock) {
              UdVideoBlock.appendChild(UdvideoBlocks);
              UdVideoBlock.classList.remove('hidden');
              
              // 추가된 동영상에 HLS 적용
              const videoElement = document.getElementById(`hlsVideoId-${VideoID}`);
              if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(`https://dud2u9ot47xno.cloudfront.net/${HlsVideoUrl}`);
                hls.attachMedia(videoElement);
              } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                videoElement.src = `https://dud2u9ot47xno.cloudfront.net/${HlsVideoUrl}`;
              }
            }            
          }
        } else {
          BlockSaveBtn.textContent = "저장하기";
          BlockSaveBtn.style="background-color:blue; color:white;";
          SaveValue.textContent = "no";
          fetch(`/api/${VideoId}/save/cancle`, { method: "POST" });


          // 유저 디테일 비디오블락 부분 동일하게 변경
          if(UdBlock){
            const UdBlockSaveBtn = UdBlock.querySelector('.uDblock-saveBtn');
            const UdSaveValue = UdBlock.querySelector('.uDsaveValue');
            UdBlockSaveBtn.textContent = "저장하기";
            UdBlockSaveBtn.style="background-color:blue; color:white;";
            UdSaveValue.textContent = "no";
          }

          // VideoId 값과 동일한 UdBlock 는 지우기!
          const UdBlock = document.querySelector(`.uD-Block[data-id="${VideoId}"]`);
          if (UdBlock) {
            UdBlock.remove();
          }
        }
      }
      BlockSaveBtn.addEventListener('click', blockSaveBtnClick);
      BlockSaveBtn.classList.add('addListener'); // 중복방지
    }
  } else if(UdSaveValue && Hash.includes('#uDvideodetail')){
    const UdBlockSaveBtn = HashVideo.querySelector('.uDblock-saveBtnBg button');
    const VideoIdString = Hash.split('videoId=')[1];
    const VideoId = VideoIdString.split('/?')[0];
    const Block = document.querySelector(`.Block[data-id="${VideoId}"]`);


    if(UdSaveValue.textContent === "no"){
      UdBlockSaveBtn.textContent = "저장하기";
      UdBlockSaveBtn.style="background-color:blue; color:white;";
    } else {
      UdBlockSaveBtn.textContent = "저장완료";
      UdBlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
    }

    // UdBlockSaveBtn 버튼 클릭 시 
    if(UdBlockSaveBtn && !UdBlockSaveBtn.classList.contains('uDaddListener')){
      function uDSaveBtnClick(){
        if(UdSaveValue.textContent === "no"){
          UdBlockSaveBtn.textContent = "저장완료";
          UdBlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
          UdSaveValue.textContent = "yes";
          fetch(`/api/${VideoId}/save`, { method: "POST" });

          // 블락 부분 동일하게 변경
          if(Block){
            const BlockSaveBtn = Block.querySelector('.block-saveBtn');
            const BlockSaveValue = Block.querySelector('.saveValue');
            BlockSaveBtn.textContent = "저장완료";
            BlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
            BlockSaveValue.textContent = "yes";
          }


          // 나의 피드에 추가
          const UdVideoBlock = document.querySelector('.uDvideoBlock');
          const HashUdVideoBlock = HashVideo.querySelector('.uD-Block');
          const UdVideoID = VideoId;
          const UdHlsVideoUrl = HashUdVideoBlock.querySelector('.uDhlsVideoUrl').textContent.trim();
          const UdThumbnailUrl = HashUdVideoBlock.querySelector('.uDthumbnailUrl').textContent.trim();
          const UDThumbnailTitle = HashUdVideoBlock.querySelector('.uDthumbnailTitle').textContent.trim();
          if(HashUdVideoBlock){
            const UdvideoBlocks = document.createElement('div');
            UdvideoBlocks.innerHTML = `
              <div class="uD-Block" data-videoUrl="${UdHlsVideoUrl}" data-id="${UdVideoID}">
                <a href="#uDvideodetail/videoId=${UdVideoID}/?${UdHlsVideoUrl}">
                  <div class="uDthumbnail-bg">
                    ${UdThumbnailUrl === "thumbnail-None" ? `
                      <video class="uDthumbnail uDthumbnailVideos" id="ud-hlsVideoId-${UdVideoID}" muted autoplay loop controlsList="nodownload"></video>
                    ` : `
                      <img class="uDthumbnail uDthumbnailImg" src="${UdThumbnailUrl}" alt="Img">
                      <video class="uDthumbnail uDthumbnailVideo" id="ud-hlsVideoId-${UdVideoID}" muted autoplay loop controlsList="nodownload"></video>
                    `}
                  </div>
                </a>
                <span class="uDthumbnailTitle">${UDThumbnailTitle}</span>
                <div class="uDblock-saveBtnBg">
                  <button class="uDblock-saveBtn"></button>
                </div>
                <span class="uDsaveValue hidden">yes</span>
                <span class="uDvideoUrl hidden">${UdHlsVideoUrl}</span>
                <span class="uDthumbnailUrl hidden">${UdThumbnailUrl}</span>
              </div>
            `;
            
            if (UdVideoBlock) {
              UdVideoBlock.appendChild(UdvideoBlocks);
              UdVideoBlock.classList.remove('hidden');
              
              // HLS.js를 사용하여 추가된 비디오에 HLS 적용
              const videoElement = document.getElementById(`ud-hlsVideoId-${UdVideoID}`);
              if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(`https://dud2u9ot47xno.cloudfront.net/${UdHlsVideoUrl}`);
                hls.attachMedia(videoElement);
              } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                videoElement.src = `https://dud2u9ot47xno.cloudfront.net/${UdHlsVideoUrl}`;
              }
            }            
          }
        } else {
          UdBlockSaveBtn.textContent = "저장하기";
          UdBlockSaveBtn.style="background-color:blue; color:white;";
          UdSaveValue.textContent = "no";
          fetch(`/api/${VideoId}/save/cancle`, { method: "POST" });

          // 블락 부분 동일하게 변경
          if(Block){
            const BlockSaveBtn = Block.querySelector('.block-saveBtn');
            const BlockSaveValue = Block.querySelector('.saveValue');
            BlockSaveBtn.textContent = "저장하기";
            BlockSaveBtn.style="background-color:blue; color:white;";
            BlockSaveValue.textContent = "no";
          }
          
          // 삭제할 VideoId 지정
          removeUdVideoBlock = VideoId;
        }
      }
      UdBlockSaveBtn.addEventListener('click', uDSaveBtnClick);
      UdBlockSaveBtn.classList.add('uDaddListener'); // 중복방지
    }
  }
}



//// 유저디테일 저장된 비디오블락 지우기
function removeUdVideoBlocks(){
  const Hash = window.location.hash;
  if(Hash.includes('#users')){
    if(removeUdVideoBlock !== ""){
      const UdBlock = document.querySelector(`.uD-Block[data-id="${removeUdVideoBlock}"]`);
      UdBlock.remove();

      // 1초 후에 removeUdVideoBlock을 초기화
      setTimeout(() => {
        removeUdVideoBlock = "";
      }, 1000);
    }
  }
}
if(UserID){
  window.addEventListener('hashchange', removeUdVideoBlocks);
}





//// [ 비디오디테일 해시비디오 ]
function hashVideo() {
  const Hash = window.location.hash;
  const HashVideo = document.querySelector('.hashVideo');
  const LoadVideo = document.querySelector('.loadVideo');
  LoadVideo.innerHTML = ``;

  if (Hash.includes('#videodetail')) {
    const HlsVideoUrl = Hash.split('?')[1];
    const Block = document.querySelector(`.Block[data-videoUrl="${HlsVideoUrl}"]`);
    //const BlockVideo = Block.querySelector('video');
    //BlockVideo.currentTime = 0.1; // 좀더 빠르게 시작하기 위해 0.1로 지정
    const HashVideoBlock = HashVideo.querySelector('.Block');
    const HashVideoBlocks = HashVideo.querySelector('.uD-Block');
    if(HashVideoBlock){
      document.querySelector('.videoBlock').appendChild(HashVideoBlock); // 복사본을 원래 위치에 추가
    }
    if(HashVideoBlocks){
      document.querySelector('.uDvideoBlock').appendChild(HashVideoBlocks); // 복사본을 원래 위치에 추가
    }
    if (Block) {
      HashVideo.innerHTML = '';
      HashVideo.appendChild(Block);
      const ThumbnailVideos = HashVideo.querySelector('.thumbnailVideos');
      const ThumbnailImg = HashVideo.querySelector('.thumbnailImg');
      const ThumbnailVideo = HashVideo.querySelector('.thumbnailVideo');
      if (ThumbnailVideos){ ThumbnailVideos.style="display:block;"}
      if (ThumbnailVideo){ ThumbnailVideo.style="display:block;"}
      if (ThumbnailImg){ThumbnailImg.style="display:none;"}
    }else{
      loadVideo();
      // 블락이 없을 경우 (예: 저장을 취소하고 유저디테일로 뒤로가기 했다가 다시 뒤로가기 했을경우에는 블락이 없기때문에 로드비디오로 들고온다.)
    }
    
    const VideoBlock = document.querySelector('.videoBlock');
    const Blocks = VideoBlock.querySelectorAll('.Block');
    Blocks.forEach(block => {
      block.style.display = 'block'; // 모든요소 보여라!
      const ThumbnailVideos = block.querySelector('.thumbnailVideos');
      const ThumbnailImg = block.querySelector('.thumbnailImg');
      const ThumbnailVideo = block.querySelector('.thumbnailVideo');
      if (ThumbnailVideos){ ThumbnailVideos.style="display:block;"}
      if (ThumbnailVideo){ ThumbnailVideo.style="display:none;"}
      if (ThumbnailImg){ThumbnailImg.style="display:block;"}
    });
  } else if(Hash.includes('#uDvideodetail')){
      const HlsVideoUrl = Hash.split('?')[1];
      const UdBlock = document.querySelector(`.uD-Block[data-videoUrl="${HlsVideoUrl}"]`);
      //const UdBlockVideo = UdBlock.querySelector('video');
      //UdBlockVideo.currentTime = 0.1; // 좀더 빠르게 시작하기 위해 0.1로 지정
      const HashVideoBlock = HashVideo.querySelector('.uD-Block');
      if(HashVideoBlock){
        document.querySelector('.uDvideoBlock').appendChild(HashVideoBlock); // 복사본을 원래 위치에 추가
      }
      if (UdBlock) {
        HashVideo.innerHTML = '';
        HashVideo.appendChild(UdBlock);
        const UdThumbnailVideos = HashVideo.querySelector('.uDthumbnailVideos');
        const UdThumbnailImg = HashVideo.querySelector('.uDthumbnailImg');
        const UdThumbnailVideo = HashVideo.querySelector('.uDthumbnailVideo');
        if (UdThumbnailVideos){ UdThumbnailVideos.style="display:block;"}
        if (UdThumbnailVideo){ UdThumbnailVideo.style="display:block;"}
        if (UdThumbnailImg){UdThumbnailImg.style="display:none;"}
      }else{
        loadVideo();
        // 블락이 없을 경우 (예: 저장을 취소하고 유저디테일로 뒤로가기 했다가 다시 뒤로가기 했을경우에는 블락이 없기때문에 로드비디오로 들고온다.)
      }
  
      const VideoBlock = document.querySelector('.videoBlock');
      const Blocks = VideoBlock.querySelectorAll('.Block');
      Blocks.forEach(block => {
        block.style.display = 'block'; // 모든요소 보여라!
        const ThumbnailVideos = block.querySelector('.thumbnailVideos');
        const ThumbnailImg = block.querySelector('.thumbnailImg');
        const ThumbnailVideo = block.querySelector('.thumbnailVideo');
        if (ThumbnailVideos){ ThumbnailVideos.style="display:block;"}
        if (ThumbnailVideo){ ThumbnailVideo.style="display:none;"}
        if (ThumbnailImg){ThumbnailImg.style="display:block;"}
      });

      // VideoId 값과 동일한 BlockVideoId 는 안보이게 하기
      const VideoIdString = Hash.split('videoId=')[1];
      const VideoId = VideoIdString.split('/?')[0];
      const Block = document.querySelector(`.Block[data-id="${VideoId}"]`);
      const BlockVideoId = document.querySelector(`span.block-videoId[data-id="${VideoId}"]`);
      if (VideoId === BlockVideoId.textContent) {
        Block.style="display:none";
      }
  } else {
    const HashVideoBlock = HashVideo.querySelector('.Block');
    const HashVideoBlocks = HashVideo.querySelector('.uD-Block');
    if(HashVideoBlock){
      document.querySelector('.videoBlock').appendChild(HashVideoBlock); // 복사본을 원래 위치에 추가
    }
    if(HashVideoBlocks){
      document.querySelector('.uDvideoBlock').appendChild(HashVideoBlocks); // 복사본을 원래 위치에 추가
    }
    HashVideo.innerHTML = '';

    // 비디오블락
    const Blocks = document.querySelectorAll('.Block');
    Blocks.forEach(block => {
      block.style.display = 'block'; // 모든요소 보여라!
      const ThumbnailVideos = block.querySelector('.thumbnailVideos');
      const ThumbnailImg = block.querySelector('.thumbnailImg');
      const ThumbnailVideo = block.querySelector('.thumbnailVideo');
      if (ThumbnailVideos){ ThumbnailVideos.style="display:block; border-radius:17px;"}
      if (ThumbnailVideo){ ThumbnailVideo.style="display:none; border-radius:17px;"}
      if (ThumbnailImg){ThumbnailImg.style="display:block; border-radius:17px;"}
    });
    
    // 유저비디오 블락
    const UdVideoBlock = document.querySelector('.uDvideoBlock');
    const UdBlocks = UdVideoBlock.querySelectorAll('.uD-Block');
    UdBlocks.forEach(block => {
      block.style.display = 'block'; // 모든요소 보여라!
      const UdThumbnailVideos = block.querySelector('.uDthumbnailVideos');
      const UdThumbnailImg = block.querySelector('.uDthumbnailImg');
      const UdThumbnailVideo = block.querySelector('.uDthumbnailVideo');
      if (UdThumbnailVideos){ UdThumbnailVideos.style="display:block;"}
      if (UdThumbnailVideo){ UdThumbnailVideo.style="display:none;"}
      if (UdThumbnailImg){UdThumbnailImg.style="display:block;"}
    });
  }
  hashSaveBtn();
}
if(UserID){
  window.addEventListener('hashchange', hashVideo);
}
// --------------------------- 여기서 부터는 해시 변경 끝.

















// --------------------------- 여기서 부터는 로드 시
//// [ 로드 저장버튼 클릭 시 ]
function loadSaveBtn(){
  const Hash = window.location.hash;
  const LoadVideo = document.querySelector('.loadVideo');
  const LoadSaveValue = LoadVideo.querySelector('.loadSaveValue');
  const LoadVideoSaveBtn = LoadVideo.querySelector('.loadVideo-saveBtn');
  if(LoadSaveValue){
    const VideoIdString = Hash.split('videoId=')[1];
    const VideoId = VideoIdString.split('/?')[0];
    const Block = document.querySelector(`.Block[data-id="${VideoId}"]`);

    if(LoadSaveValue.textContent === "no"){
      LoadVideoSaveBtn.textContent = "저장하기";
      LoadVideoSaveBtn.style="background-color:blue; color:white;";
    } else {
      LoadVideoSaveBtn.textContent = "저장완료";
      LoadVideoSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
    }

    // LoadVideoSaveBtn 버튼 클릭 시 
    if(LoadVideoSaveBtn && !LoadVideoSaveBtn.classList.contains('addListener')){
      function loadSaveBtnClick(){
        if(LoadSaveValue.textContent === "no"){
          LoadVideoSaveBtn.textContent = "저장완료";
          LoadVideoSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
          LoadSaveValue.textContent = "yes";
          fetch(`/api/${VideoId}/save`, { method: "POST" });

          // 블락 부분 동일하게 변경
          if(Block){
            const BlockSaveBtn = Block.querySelector('.block-saveBtn');
            const BlockSaveValue = Block.querySelector('.saveValue');
            BlockSaveBtn.textContent = "저장완료";
            BlockSaveBtn.style="background-color:rgb(234, 234, 234); color:rgb(34, 34, 34);";
            BlockSaveValue.textContent = "yes";
          }

          // 나의 피드에 추가
          const UdVideoBlock = document.querySelector('.uDvideoBlock');
          const LoadVideoInfo = LoadVideo.querySelector('.loadVideoInfo');
          const LoadVideoThumbnailUrl = LoadVideoInfo.querySelector('.loadVideo-thumbnailUrl').textContent.trim();
          const LoadVideoTitle = LoadVideoInfo.querySelector('.loadVideo-title').textContent.trim();
          const HlsVideoUrl = Hash.split('?')[1];
          if(LoadVideo){
            const UdvideoBlocks = document.createElement('div');
            UdvideoBlocks.innerHTML = `
              <div class="uD-Block" data-videoUrl="${HlsVideoUrl}" data-id="${VideoId}">
                <a href="#uDvideodetail/videoId=${VideoId}/?${HlsVideoUrl}">
                  <div class="uDthumbnail-bg">
                    ${LoadVideoThumbnailUrl === "thumbnail-None" ? `
                      <video class="uDthumbnail uDthumbnailVideos" id="ud-hlsVideoID-${VideoId}" muted autoplay loop controlsList="nodownload"></video>
                    ` : `
                      <img class="uDthumbnail uDthumbnailImg" src="${LoadVideoThumbnailUrl}" alt="Img">
                      <video class="uDthumbnail uDthumbnailVideo" id="ud-hlsVideoID-${VideoId}" muted autoplay loop controlsList="nodownload"></video>
                    `}
                  </div>
                </a>
                <span class="uDthumbnailTitle">${LoadVideoTitle}</span>
                <div class="uDblock-saveBtnBg">
                  <button class="uDblock-saveBtn"></button>
                </div>
                <span class="uDsaveValue hidden">yes</span>
                <span class="uDvideoUrl hidden">${HlsVideoUrl}</span>
                <span class="uDthumbnailUrl hidden">${LoadVideoThumbnailUrl}</span>
              </div>
            `;
            
            if (UdVideoBlock) {
              UdVideoBlock.appendChild(UdvideoBlocks);
              UdVideoBlock.classList.remove('hidden');
              
              // HLS.js를 사용하여 추가된 비디오에 HLS 적용
              const videoElement = document.getElementById(`ud-hlsVideoID-${VideoId}`);
              if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(`https://dud2u9ot47xno.cloudfront.net/${HlsVideoUrl}`);
                hls.attachMedia(videoElement);
              } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                videoElement.src = `https://dud2u9ot47xno.cloudfront.net/${HlsVideoUrl}`;
              }
            }            
          }
        } else {
          LoadVideoSaveBtn.textContent = "저장하기";
          LoadVideoSaveBtn.style="background-color:blue; color:white;";
          LoadSaveValue.textContent = "no";
          fetch(`/api/${VideoId}/save/cancle`, { method: "POST" });

          // 블락 부분 동일하게 변경
          if(Block){
            const BlockSaveBtn = Block.querySelector('.block-saveBtn');
            const BlockSaveValue = Block.querySelector('.saveValue');
            BlockSaveBtn.textContent = "저장하기";
            BlockSaveBtn.style="background-color:blue; color:white;";
            BlockSaveValue.textContent = "no";
          }

          // VideoId 값과 동일한 UdBlock 는 지우기!
          const UdBlock = document.querySelector(`.uD-Block[data-id="${VideoId}"]`);
          if (UdBlock) {
            UdBlock.remove();
          }
        }
      }
      LoadVideoSaveBtn.addEventListener('click', loadSaveBtnClick);
      LoadVideoSaveBtn.classList.add('addListener'); // 중복방지
    }
  }
}



//// [ 비디오디테일 로드비디오]
function loadVideo(){
  const Hash = window.location.hash;
  const LoadVideo = document.querySelector('.loadVideo');
  
  // 비디오블락
  const Blocks = document.querySelectorAll('.Block');
  Blocks.forEach(block => {
    block.style.display = 'block'; // 모든요소 보여라!
    const ThumbnailVideos = block.querySelector('.thumbnailVideos');
    const ThumbnailImg = block.querySelector('.thumbnailImg');
    const ThumbnailVideo = block.querySelector('.thumbnailVideo');
    if (ThumbnailVideos){ ThumbnailVideos.style="display:block; border-radius:17px;"}
    if (ThumbnailVideo){ ThumbnailVideo.style="display:none; border-radius:17px;"}
    if (ThumbnailImg){ThumbnailImg.style="display:block; border-radius:17px;"}
  });
  
  // 유저비디오 블락
  const UdVideoBlock = document.querySelector('.uDvideoBlock');
  const UdBlocks = UdVideoBlock.querySelectorAll('.uD-Block');
  UdBlocks.forEach(block => {
    block.style.display = 'block'; // 모든요소 보여라!
    const UdThumbnailVideos = block.querySelector('.uDthumbnailVideos');
    const UdThumbnailImg = block.querySelector('.uDthumbnailImg');
    const UdThumbnailVideo = block.querySelector('.uDthumbnailVideo');
    if (UdThumbnailVideos){ UdThumbnailVideos.style="display:block;"}
    if (UdThumbnailVideo){ UdThumbnailVideo.style="display:none;"}
    if (UdThumbnailImg){UdThumbnailImg.style="display:block;"}
  });

  if (Hash.includes('#videodetail') || Hash.includes('#uDvideodetail')) {
    const VideoIdString = Hash.split('videoId=')[1];
    const VideoId = VideoIdString.split('/?')[0];
    fetch(`/api/${VideoId}/videoInfo`, { method: "POST" })
      .then(response => response.json())
      .then(data => {
        if (data.video) {
          LoadVideo.innerHTML = `
            <div class ="loadVideoInfo">
              <video id="loadVideo-${data.video.id}" class="loadVideo-video" muted autoplay loop controlsList="nodownload"></video>
              <span class="loadVideo-title">${data.video.title}</span>
              ${data.video.creatorValue !== "me" ? `
                <div class="loadVideo-saveBtnBg">
                  <button class="loadVideo-saveBtn">저장하기</button>
                </div>
                <span class="loadSaveValue hidden">${data.video.saveValue}</span>
              ` : ''}
              <span class="loadVideo-thumbnailUrl hidden">${data.video.thumbnailUrl}</span>
            </div>
          `;
        
          // HLS.js로 비디오 설정
          const video = document.getElementById(`loadVideo-${data.video.id}`);
          if (Hls.isSupported() && video) {
            const hls = new Hls();
            hls.loadSource(`https://dud2u9ot47xno.cloudfront.net/${data.video.hlsVideoUrl}`);
            hls.attachMedia(video);
          } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = `https://dud2u9ot47xno.cloudfront.net/${data.video.hlsVideoUrl}`;
          }
        
          loadSaveBtn();
        }
      });

    // VideoId 값과 동일한 BlockVideoId 는 안보이게 하기
    const Block = document.querySelector(`.Block[data-id="${VideoId}"]`);
    const BlockVideoId = document.querySelector(`span.block-videoId[data-id="${VideoId}"]`);
    if (VideoId === BlockVideoId.textContent) {
      Block.style="display:none";
    }
  }else{
    LoadVideo.innerHTML = ``;
  }
}
if(UserID){
  window.addEventListener('load', loadVideo);
}
// --------------------------- 여기서 부터는 해시 로드 끝.





//// [ 비디오 소리 - 해시 비디오만 적용 / 로드는 재생버튼 클릭 시에 적용 ]
const MutedBtn = document.querySelector('.mutedBtn');
function muted(){
  const Hash = window.location.hash;
  const validHashes = ['#videodetail', '#uDvideodetail'];
  const allVideos = document.querySelectorAll('video');
  
  // 모든 비디오의 소리를 먼저 끈다
  allVideos.forEach(video => {
    video.muted = true ;
  });
  
  // 특정 해시가 있는 경우에만 버튼 클릭으로 소리를 켠다
  if (validHashes.some(validHash => Hash.includes(validHash))) {
    MutedBtn.click();
  }
}
window.addEventListener('hashchange', muted);


function MutedBtnClick() {
  const VideoDetail = document.querySelector('.videoDetail');
  const HashVideo = document.querySelector('.hashVideo');
  if (HashVideo) {
    const HashVideoPlayer = HashVideo.querySelector('video');
    if (HashVideoPlayer) {
      HashVideoPlayer.muted = false;
      VideoDetail.style.display = "flex";
    }
  }
}
MutedBtn.removeEventListener('click', MutedBtnClick);
MutedBtn.addEventListener('click', MutedBtnClick);