//  <!-- 공통 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 전체 스크롤 해시 / 로드 ]
const BodyContent = document.querySelector('.bodyContent');
function hideScrollbar() {
  document.body.style.overflow = 'hidden';
}
function showScrollbar() {
  document.body.style.overflow = 'auto';
}
function scrollJS(){
  if(BodyContent){
    BodyContent.scrollTop = 0;
  }
  hideScrollbar();
  document.body.scrollIntoView();
  showScrollbar();
}
window.addEventListener('hashchange', scrollJS);
window.addEventListener('load', scrollJS);





//// [ 모바일 뒤로가기 클릭 시]
if(UserID){
  function mobileBackJS(){
    const MobileBack = document.querySelector('.mobileBack');
    const Hash = window.location.hash;
  
    // 표시할 해시 값들을 배열로 설정
    const validHashes = ['#edit-profile', '#change-password', '#withdrawal', '#inquiryupload' ];
  
    if (window.innerWidth > 1000) {
        // 화면 크기가 1000px 초과일 경우 무조건 안보이게
        MobileBack.style = 'display:none';
    } else if (validHashes.some(validHash => Hash.includes(validHash))) {
        MobileBack.style = 'display:flex;';
    } else {
        MobileBack.style = 'display:none';
    }
  }
  window.addEventListener('hashchange', mobileBackJS);
  window.addEventListener('load', mobileBackJS);
  window.addEventListener('resize', mobileBackJS);
  document.getElementById('mobileBackIcon').addEventListener('click', function() {
    window.history.back();
  });
}



//// [ 모바일 확인 아이콘 클릭 시 ]
if(UserID){
  const Confirm = document.getElementById('confirm');
  Confirm.innerHTML = '<i class="bi bi-check-lg"></i>';
  const EditProfileSubmitBtn = document.querySelector('.editProfile_submit');
  const ChangePasswordSubmitBtn = document.querySelector('.changePassword_submit'); 
  const WithdrawalSubmitBtn = document.querySelector('.withdrawal_submit'); 
  const InquiryUploadSubmitBtn = document.querySelector('.inquiryUpload_submit'); 
  Confirm.addEventListener('click', function() {
    const Hash = window.location.hash;
    if(Hash.includes('#edit-profile')){
      EditProfileSubmitBtn.click();
    }else if(Hash.includes('#change-password')){
      ChangePasswordSubmitBtn.click();
    }else if(Hash.includes('#withdrawal')){
      WithdrawalSubmitBtn.click();
    }else if(Hash.includes('#inquiryupload')){
      InquiryUploadSubmitBtn.click();
    }
  });
}