// <!-- 비디오업로드 JAVASCRIPT -->
const UserID = document.querySelector(".userID");




//// [ 비디오업로드 언어번역 ]
function getVideoUpload(){
  const UploadSubmit = document.querySelector(".upload_submit");
  const UploadVideoText = {
    en: { UploadSubmit: "Upload"},
    ko: { UploadSubmit: "업로드"}
  }
  const Language = navigator.language.split("-")[0];
  const Text = UploadVideoText[Language] || UploadVideoText.en;
  if (UploadSubmit !== null) UploadSubmit.textContent = Text.UploadSubmit;
}
if(UserID){
  getVideoUpload();
}






//// [ 비디오업로드 해쉬/로드 ]
function videoUploadJS() {
  const VideoUpload = document.querySelector(".videoUpload");
  const PlayerImg = document.querySelector('.playerImg');
  const PlayerVideo = document.querySelector('.playerVideo');
  const ThumbnailUploadInput = document.querySelector('.thumbnailUpload_Input');
  const ThumbnailUploadVideo = document.querySelector('.thumbnailUpload_video');
  const ThumbnailUploadImg = document.querySelector('.thumbnailUpload_img');
  const UploadTitle = document.querySelector(".upload_title");
  const UploadDescription = document.querySelector(".upload_description");
  const VideoUploadBtnAlert = document.querySelector('.videoUpload-btn-alert');
  const NextStepOne = document.getElementById('nextStepOne');
  PlayerImg.style.display = 'block';
  PlayerVideo.style.display = 'none';
  PlayerVideo.src = "";
  ThumbnailUploadInput.value = "";
  ThumbnailUploadVideo.src = "";
  ThumbnailUploadImg.src = "";
  UploadTitle.value = "";
  UploadDescription.value = "";
  VideoUploadBtnAlert.style.display="block";
  NextStepOne.style.display="none";
  
  if(window.location.hash === `#videoupload/${UserID.innerText}`){
    VideoUpload.style.display="flex";
  } else {
    VideoUpload.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoUploadJS);
  window.addEventListener('load', videoUploadJS);
}






//// [ 비디오 업로드 알람창 ]
function videoUploadBtnAlert(){
  const VideoUploadBtnAlert = document.querySelector('.videoUpload-btn-alert');
  VideoUploadBtnAlert.addEventListener('click', () => {
    alert("비디오를 업로드 하여주세요.");
  });
}
if(UserID){
  videoUploadBtnAlert();
}







//// [ 비디오 가져오기 ]
function videoChange() {
  const PlayerImg = document.querySelector('.playerImg');
  const PlayerVideo = document.querySelector('.playerVideo');
  const Videofiles = document.querySelector('.videofiles');
  const ThumbnailUploadVideo = document.querySelector('.thumbnailUpload_video');
  const VideoUploadBtnAlert = document.querySelector('.videoUpload-btn-alert');
  const NextStepOne = document.getElementById('nextStepOne');
  const ThumbnailUploadImg = document.querySelector('.thumbnailUpload_img');

  // 이벤트 핸들러 정의
  const handlePlayerClick = () => {
    Videofiles.click();
  };

  const handleVideoChange = (event) => {
    const File = event.target.files[0];
    if (File) {
      const VideoURL = URL.createObjectURL(File);
      PlayerVideo.src = VideoURL;
      ThumbnailUploadVideo.src = VideoURL;
      VideoUploadBtnAlert.style.display = "none";
      NextStepOne.style.display = "block";
      ThumbnailUploadImg.style.display = "none";
      NextStepOne.click();
      ThumbnailUploadVideo.style.display = "block";
      PlayerVideo.style.display = 'block';
      PlayerImg.style.display = 'none';
    }
  };

  // 기존 이벤트 제거 후 다시 등록
  PlayerImg.removeEventListener('click', handlePlayerClick);
  PlayerVideo.removeEventListener('click', handlePlayerClick);
  Videofiles.removeEventListener('change', handleVideoChange);

  PlayerImg.addEventListener('click', handlePlayerClick);
  PlayerVideo.addEventListener('click', handlePlayerClick);
  Videofiles.addEventListener('change', handleVideoChange);
}

if (UserID) {
  videoChange();
}










//// [ 썸네일 이미지 가져오기 ]
function thumbnailChange() {
  const ThumbnailUploadBtn = document.querySelector('.thumbnailUpload_btn');
  const ThumbnailUploadInput = document.querySelector('.thumbnailUpload_Input');
  const ThumbnailUploadVideo = document.querySelector('.thumbnailUpload_video');
  const ThumbnailUploadImg = document.querySelector('.thumbnailUpload_img');

  // 이벤트 핸들러 정의
  const handleThumbnailClick = () => {
    ThumbnailUploadInput.click();
  };

  const handleThumbnailChange = (event) => {
    const File = event.target.files[0];
    if (File) {
      const Reader = new FileReader();
      Reader.onload = function (e) {
        ThumbnailUploadImg.src = e.target.result;
        ThumbnailUploadVideo.style.display = "none";
        ThumbnailUploadImg.style.display = "block";
      };
      Reader.readAsDataURL(File);
    }
  };

  // 기존 이벤트 제거 후 다시 등록
  ThumbnailUploadBtn.removeEventListener('click', handleThumbnailClick);
  ThumbnailUploadVideo.removeEventListener('click', handleThumbnailClick);
  ThumbnailUploadImg.removeEventListener('click', handleThumbnailClick);
  ThumbnailUploadInput.removeEventListener('change', handleThumbnailChange);

  ThumbnailUploadBtn.addEventListener('click', handleThumbnailClick);
  ThumbnailUploadVideo.addEventListener('click', handleThumbnailClick);
  ThumbnailUploadImg.addEventListener('click', handleThumbnailClick);
  ThumbnailUploadInput.addEventListener('change', handleThumbnailChange);
}

if (UserID) {
  thumbnailChange();
}








//// [ 비디오 업로드 프로튼 ]
// 참고: function uploadSubmitClick() 앞에 async 키워드를 붙이는 이유는 그 함수 내에서 await를 사용하여 비동기 작업의 완료를 기다리고, 순차적으로 코드를 실행할 수 있게 하기 위함입니다.
async function uploadSubmitClick() {
  const UploadSubmit = document.querySelector(".upload_submit");
  UploadSubmit.addEventListener('click', async () => {
    const Videofiles = document.querySelector(".videofiles").files[0];
    const ThumbnailInputs = document.querySelector('.thumbnailUpload_Input').files[0];
    const UploadTitle = document.querySelector(".upload_title").value;
    const UploadDescription = document.querySelector(".upload_description").value;

    const VideoData = {
      videoName: Videofiles.name,
      videoContentType: Videofiles.type,
      thumbnailName: ThumbnailInputs ? ThumbnailInputs.name : null,
      thumbnailContentType: ThumbnailInputs ? ThumbnailInputs.type : null
    };

    try {
      const VideoPresignedRes = await fetch(`/videos/videoPresigned`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(VideoData)
      });

      if (!VideoPresignedRes.ok) {
        throw new Error('Pre-signed URL 요청 실패');
      }

      const { urls, s3VideoURLS, thumbnailURLS } = await VideoPresignedRes.json();


      // S3에 파일을 업로드하는 함수
      async function videoUploadToS3(url, file) {
        const Response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,  // 파일 타입 설정
          },
          body: file,  // 업로드할 파일 데이터
        });
  
        if (!Response.ok) {
          throw new Error(`파일 업로드 실패: ${file.name}`);
        }
      }


      // 썸네일 리사이징 함수 (넓이만 300으로 설정)
      async function thumbnailResizeImg(imageFile, maxWidth) {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        return new Promise((resolve, reject) => {
          img.onload = () => {
            let width = img.width;
            let height = img.height;
  
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
  
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
  
            canvas.toBlob((blob) => {
              resolve(blob);
            }, imageFile.type);
          };
  
          img.onerror = (error) => reject(error);
          img.src = URL.createObjectURL(imageFile);
        });
      }


      // 병렬로 업로드 수행
      const uploadPromises = [];

      if (urls.thumbnail && ThumbnailInputs) {
        const resizedThumbnailBlob = await thumbnailResizeImg(ThumbnailInputs, 300);
        uploadPromises.push(videoUploadToS3(urls.thumbnail, resizedThumbnailBlob));
      }

      if (urls.video) {
        uploadPromises.push(videoUploadToS3(urls.video, Videofiles));
      }

      // 모든 업로드가 완료될 때까지 대기
      await Promise.all(uploadPromises);


      // S3 업로드가 완료된 후 '/api/videoUpload'로 데이터 전송
      const VideoUploadRes = await fetch('/videos/videoUpload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          s3VideoUrl: s3VideoURLS,
          thumbnailUrl: thumbnailURLS,
          title: UploadTitle,
          description: UploadDescription
        })
      });
  
      if (!VideoUploadRes.ok) {
        throw new Error('비디오 업로드 중 오류 발생');
      }

      const { videoId } = await VideoUploadRes.json();
      if (videoId) {
        window.location.reload();
      } else {
        throw new Error('videoId를 받지 못했습니다.');
      }

    } catch (error) {
      console.error('업로드 중 오류 발생:', error);
    }
  });
}
if (UserID) {
  uploadSubmitClick();
}